import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import axios from 'axios'
import { Box, Chip, CircularProgress, colors } from '@mui/material'
import { RemoveRedEyeSharp, EditSharp } from '@mui/icons-material'

import { useStore } from '../store/StoredData'

const People = () => {
    const history = useHistory();
    const {
        searchOn, setSearchOn,
        personName, setPersonName,
        personCik, setPersonCik,
        peopleList, setPeopleList
    } = useStore();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchPeople = async () => {
            setPeopleList([]);
            setLoading(true);
            console.log('Calling Articles API For Date Range ::: ', 'name ::: ', personName, 'CIK ::: ', personCik);

            await axios.post(`/api/search/people`, { name: personName, cik: personCik }).then(async resp => {
                console.log('RECVD FROM API Articles ::: ', resp.data.message.length, resp.data.message);
                if (resp.data.message.length) {
                    setPeopleList(resp.data.message);
                } else {
                    setPeopleList([]);
                }
            }).catch(console.error);
        };

        if (personName || personCik)
            fetchPeople().then(() => setLoading(false), setSearchOn(false));

    }, [searchOn])

    return (
        <div style={{ display: 'flex' }}>
            {loading
                ? <Box display="flex" width="100%" justifyContent={"center"} alignItems={"center"} margin={"15%"}><CircularProgress /></Box>
                : <>
                    {peopleList && peopleList.length
                        ? <Box display="flex" width="100%" justifyContent={"center"} alignContent="center" alignItems={"center"}>
                            <ul style={{ width: '60%', border: '1px solid #CCC', listStyle: 'none', marginLeft: 0, paddingLeft: 0, textAlign: 'left' }}>
                                <li>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: 5, margin: 3, backgroundColor: '#EAEAEA', border: '1px solid #CCC', borderRadius: 5, fontSize: 13 }}>
                                        <div style={{ width: '15%', textAlign: 'left', margin: '2px 5px' }}>
                                            <span><strong>Action</strong></span>
                                        </div>
                                        <div style={{ width: '30%', textAlign: 'left', margin: '2px 5px' }}>
                                            <span ><strong>Person Name</strong></span>
                                        </div>
                                        <div style={{ width: '15%', textAlign: 'left', margin: '2px 5px' }}>
                                            <span><strong>Person CIK</strong></span>
                                        </div>
                                        <div style={{ width: '40%', textAlign: 'left', margin: '2px 5px' }}>
                                            <span><strong>Parent Companies</strong></span>
                                        </div>
                                    </div>
                                </li>
                                {peopleList.length && peopleList.map(person =>
                                    <li key={person.sk_txn_sec_people_profile}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: 5, margin: 3, border: '1px solid #CCC', borderRadius: 5, fontSize: 13 }}>
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', width: "15%", textAlign: 'left', margin: '2px 5px' }}>
                                                <div style={{ margin: '2px 5px' }}>
                                                    <a
                                                        onClick={() => history.push(`/people/view/${person.sk_txn_sec_people_profile}`)}
                                                        style={{ cursor: 'pointer', textDecoration: 'none' }}
                                                        role="button"
                                                        title='View Person Name (Read Only)'>
                                                        <RemoveRedEyeSharp color='primary' />
                                                    </a>
                                                </div>
                                                <div style={{ margin: '2px 5px' }}>
                                                    <a
                                                        onClick={() => history.push(`/people/edit/${person.sk_txn_sec_people_profile}`)}
                                                        style={{ cursor: 'pointer', textDecoration: 'none' }}
                                                        role="button"
                                                        title='Edit Article'>
                                                        <EditSharp color='secondary' />
                                                    </a>
                                                </div>
                                            </div>
                                            <div style={{ width: '30%', textAlign: 'left', margin: '2px 5px' }}>
                                                <a
                                                    // onClick={() => history.push(`/articles/edit/${article.id}`)}
                                                    style={{
                                                        // cursor: 'pointer', 
                                                        textDecoration: 'none', textTransform: 'capitalize'
                                                    }}
                                                >{person.insider_name}</a>
                                            </div>
                                            <div style={{ width: '15%', textAlign: 'left', margin: '2px 5px' }}>
                                                {person.insider_cik && <Chip label={person.insider_cik?.padStart(10, '0') || null} size="small" variant="outlined" color='primary' style={{ margin: 3 }} />}
                                            </div>
                                            {/* <div style={{ width: '15%', textAlign: 'left', margin: '2px 5px' }}>
                                                {person.insider_cik && <Chip label={person.insider_cik?.padStart(10, '0') || null} size="small" variant="outlined" color='primary' style={{ margin: 3 }} />}
                                            </div> */}
                                            <div style={{ display: "flex", flexDirection: 'row', flexWrap: 'wrap', width: '40%', textAlign: 'left', margin: '2px 5px' }}>
                                                {person.parent_cik && person.parent_cik.length > 0 ? person.parent_cik.map((obj, i) => <Chip key={i} label={obj.toString().padStart(10, '0') || null} size="small" variant="outlined" color='secondary' style={{ margin: 3 }} />) : null}
                                            </div>
                                        </div>
                                    </li>
                                )}
                            </ul></Box>
                        : <span style={{ width: '100%', textAlign: 'center', color: colors.deepOrange[600], height: 50, padding: 10 }}>
                            No Data To Display For Selected Settings
                        </span>
                    }
                </>}
        </div>
    )
}

export default People