import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Box, Button, colors, IconButton, Typography } from "@mui/material"
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import authService from '../services/auth.service';

const Header = () => {

    const history = useHistory();

    const [currentUser, setCurrentUser] = useState();

    useEffect(async () => { setCurrentUser(await authService.getCurrentUser()) }, [currentUser])

    return (
        <Box width="100%" boxShadow={2} display="flex" flexDirection="row" justifyContent="space-around" alignContent="center" alignItems="center" bgcolor="darkblue" color={colors.grey[50]}>
            <Box textAlign="left" fontSize={12} fontWeight={500} padding='2px' ml={1}>
                <a
                    onClick={() => { history.push("/home") }}
                    // href='/home'
                    title={'Reload Page'}
                    style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}>
                    <p style={{ margin: 0, padding: 0, letterSpacing: 4 }}>PEOPLE</p>
                    <p style={{ fontSize: 13, margin: 0, padding: 0, letterSpacing: 1, color: colors.amber[800] }}>CURATOR</p>
                    <p style={{ margin: 0, padding: 0, letterSpacing: 10 }}>TOOL</p>
                </a>
            </Box>
            <div style={{ width: '80%' }}>&nbsp;</div>
            <Box width="40%" display="flex">
                {currentUser
                    ? <>
                        <Box width="100%" display="flex" justifyContent="flex-end" alignContent="center" alignItems="center">
                            <Button variant='text' sx={{ color: colors.common.white }} startIcon={<PersonAddAlt1Icon />} onClick={() => history.push("/people/add")}>ADD PERSON</Button>
                            <Box width="15%">&nbsp;</Box>
                            <Typography>Hi&nbsp;</Typography>
                            <Typography>{currentUser.name}</Typography>
                            <Typography>&nbsp;&nbsp;|</Typography>
                            <IconButton href='/login' title='Logout'>
                                <PowerSettingsNewIcon color="error" onClick={() => { authService.logout(); }} />
                            </IconButton>
                        </Box>
                    </>
                    : <a href='/login' style={{ textDecoration: 'none', marginRight: 15, color: 'whitesmoke', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>Login</a>
                }
            </Box>
        </Box >
    )
}

export default Header