import React from 'react'
import { Box, colors, TextField, IconButton, Button } from '@mui/material';
import { ClearSharp, SearchSharp } from "@mui/icons-material"

import { useStore } from '../store/StoredData';

import "../../src/App.css";

const SearchBar = (props) => {
    const {
        searchOn, setSearchOn,
        personName, setPersonName,
        personCik, setPersonCik,
        peopleList, setPeopleList,
        resetSearchBar
    } = useStore();

    console.log('SEARCH-BAR PERSON-NAME AND PERSON-CIK ========== ', 'NAME ::: ', personName, 'CIK ::: ', personCik);

    return (
        <Box width="100%" boxShadow={1} border={1} borderColor={colors.blueGrey[100]}
            display="flex" justifyContent="center" alignContent="center" alignItems="center">
            <Box m={0.5}><TextField
                name='personName'
                title='Person Name'
                variant='standard'
                placeholder='Person Name'
                defaultValue={personName}
                value={personName}
                onChange={(event) => setPersonName(event.target.value)}
            /></Box>
            {/* <Box m={0.5}><TextField
                name='personCik'
                title='Person CIK'
                variant='standard'
                placeholder='Person CIK'
                defaultValue={personCik}
                value={personCik}
                onChange={(event) => setPersonCik(event.target.value)}
            /></Box> */}
            <Box m={0.5}><IconButton size='small' color='primary' onClick={() => setSearchOn(true)} title='Search For Resutls'>
                <Button type='submit' startIcon={<SearchSharp color='secondary' />} title='Search For Resutls'>search</Button>
            </IconButton></Box>
            <Box m={0.5}><IconButton size='small' color='primary' onClick={() => resetSearchBar()} title='Reset Search Bar'>
                <Button  startIcon={<ClearSharp color='secondary' />} title='Reset Search Bar'>clear</Button>
            </IconButton></Box>
        </Box>
    )
}

export default SearchBar