import React, { useEffect, useState } from 'react'

import People from "../components/People";
import SearchBar from "../components/SearchBar";
import Header from '../components/Header';

const Home = () => {

    useEffect(() => { }, [])

    return (
        <>
            <Header />
            <SearchBar />
            <People />
        </>
    )
}

export default Home