import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios';

import Person from '../components/Person';
import Header from '../components/Header';
import { Box, Button } from '@mui/material';

import { useStore } from '../store/StoredData';

const ViewPerson = () => {
  const {
    searchOn, setSearchOn,
    personName, setPersonName,
    personCik, setPersonCik,
    personProfile, setPersonProfile,
    screenLoading, setScreenLoading
  } = useStore();

  console.log('VIEW PESON PROFILE PAGE ======== ', personName, personCik);
  const params = useParams();
  const history = useHistory();

  useEffect(async () => {
    setScreenLoading(true);
    const fetchPerson = async () => {
      console.log('Calling Person By CIK API... ', params)
      await axios.get(`/api/search/people/${params.id}`).then(resp => {
        console.log('API RECVD PERSON ', resp.data.message)
        setPersonProfile(resp.data.message);
      }).catch(error => console.error(`Error While Fetching Person By CIK, `, error))
    };
    await fetchPerson().finally(() => setScreenLoading(false));
  }, [searchOn, params.id]);

  return (
    <>
      <Header />
      <Box display="flex" width="100%" flexDirection="row">
        <Box width="20%" border={1}>&nbsp;</Box>
        <Box width="60%" border={1}>
          <Person isProfileEditable={false} />
        </Box>
        <Box width="20%" border={1}>&nbsp;</Box>
      </Box>
    </>
  )
}

export default ViewPerson