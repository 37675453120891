import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios';

import Person from '../components/Person';
import Header from '../components/Header';
import ArticleKeywords from '../components/ArticleKeywords';
import MasterCategories from '../components/MasterCategories';
import AddChangeDeleteCategories from '../components/AddchangeDeleteCategories';
import { Box, Button, colors } from '@mui/material';

import { useStore } from '../store/StoredData';

const EditPerson = () => {
    const {
        searchOn, setSearchOn,
        personName, setPersonName,
        personCik, setPersonCik,
        personProfile, setPersonProfile
    } = useStore();

    console.log('EDIT ARTICLE VIEW DATERANGE ======== ', personName, personCik, personProfile);
    const history = useHistory();

    return (
        <>
            <Header />
            <Box display="flex" width="100%" flexDirection="row" bgcolor={colors.blueGrey[50]}>
                <Box width="5%" paddingTop={2}>&nbsp;</Box>
                <Box width="90%" boxShadow={1} bgcolor={colors.common.white}>
                    <Person isProfileEditable={true} />
                </Box>
                <Box width="5%" >&nbsp;</Box>
            </Box>
        </>
    )
}

export default EditPerson