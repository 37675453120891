import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom';
import { PhotoCamera } from '@mui/icons-material';
import { Box, Button, Checkbox, CircularProgress, colors, FormControl, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ClearIcon from '@mui/icons-material/Clear';
import { HourglassTopRounded } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import Header from '../components/Header';
import { useStore } from '../store/StoredData';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 3;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const AddPerson = () => {
    const { searchOn, personProfile, setPersonProfile } = useStore();

    const history = useHistory();
    const [personName, setPersonName] = useState()
    const [profilePicture, setProfilePicture] = useState("");
    const [profilePictureFile, setProfilePictureFile] = useState("");
    const [profilePhoto, setProfilePhoto] = useState()
    const [personOverviewState, setPersonOverviewState] = useState();
    const [personEarlyLifeState, setPersonEarlyLifeState] = useState();
    const [personRecognitionState, setPersonRecognitionState] = useState();
    const [personActivitiesState, setPersonActivitiesState] = useState();
    const [acValue, setACValue] = useState();

    const [overview, setOverview] = useState("");
    const [earlyLife, setEarlyLife] = useState("");
    const [education, setEducation] = useState([
        { year: '', degree: '', school: '' },
    ]);
    const [career, setCareer] = useState([
        { company: "", current_title: "" },
    ]);
    const [compensation, setCompensation] = useState([
        { company: "", compensation: null, other_compensation: null },
    ]);
    const [otherActivities, setOtherActivities] = useState([
        { activity_name: "", activity_description: "", activity_photo: "" },
        { activity_name: "", activity_description: "", activity_photo: "" },
        { activity_name: "", activity_description: "", activity_photo: "" },
        { activity_name: "", activity_description: "", activity_photo: "" },
        { activity_name: "", activity_description: "", activity_photo: "" }]);
    const [recognition, SetRecognition] = useState("");
    const [references, setReferences] = useState([
        { title: "", url: "" },
    ]);

    const [aka, setAka] = useState([]);
    const [akaList, setAkaList] = useState([]);
    const [relevancy, setRelevancy] = useState([
        "Business",
        "Sports",
        "Movies",
        "Music",
        "Art and Culture",
        "Academic",
        "Politician",
        "Government Official",
        "Philanthropist",
        "Newsmaker"]);

    const [relevancyList, setRelevancyList] = useState([]);
    const [personCIK, setPersonCIK] = useState([]);
    const [parentCompany, setParentCompany] = useState(null);
    const [parentCompanyList, setParentCompanyList] = useState([]);
    const [knownFor, setKnownFor] = useState("");
    const [knownForList, setKnownForList] = useState([]);
    const [companySuggestion, setCompanySuggestions] = useState([]);
    const [suggDropDownState, SetSuggDropDownState] = useState(false);
    const [suggLoading, setSuggLoading] = useState(false);

    const [loading, setLoading] = useState(false);

    const [convertedContentPersonOverview, setConvertedContentPersonOverview] = useState();
    const [convertedContentPersonEarlyLife, setConvertedContentPersonEarlyLife] = useState();
    const [convertedContentPersonRecognition, setConvertedContentPersonRecognition] = useState();
    const [convertedContentPersonActivities, setConvertedContentPersonActivities] = useState();

    ///////////////////////--Handle Overview --///////////////////////////////

    const handleEditorChangePersonOverview = (state) => {
        if (state) {
            setPersonOverviewState(state);
            convertContentToHTMLPersonOverview();
        }
    }
    const convertContentToHTMLPersonOverview = () => {
        let currentContentAsHTML = draftToHtml(convertToRaw(personOverviewState.getCurrentContent()));
        setConvertedContentPersonOverview(currentContentAsHTML);
        setOverview(currentContentAsHTML);
    }

    ///////////////////////--Handle EarlyLife --///////////////////////////////
    const handleEditorChangePersonEarlyLife = (state) => {
        if (state) {
            setPersonEarlyLifeState(state);
            convertContentToHTMLPersonEarlyLife();
        }
    }

    const convertContentToHTMLPersonEarlyLife = () => {
        let currentContentAsHTML = draftToHtml(convertToRaw(personEarlyLifeState.getCurrentContent()));
        setConvertedContentPersonEarlyLife(currentContentAsHTML);
        setEarlyLife(currentContentAsHTML);
    }

    ///////////////////////--Handle Recognition --///////////////////////////////

    const handleEditorChangePersonRecognition = (state) => {
        if (state) {
            setPersonRecognitionState(state);
            convertContentToHTMLPersonRecognition();
        }
    }

    const convertContentToHTMLPersonRecognition = () => {
        let currentContentAsHTML = draftToHtml(convertToRaw(personRecognitionState.getCurrentContent()));
        setConvertedContentPersonRecognition(currentContentAsHTML);
        SetRecognition(currentContentAsHTML);
    }

    ///////////////////////--Handle Activity --///////////////////////////////

    const handleEditorChangePersonActivities = (state) => {
        if (state) {
            setPersonActivitiesState(state);
            convertContentToHTMLPersonActivities();
        }
    }

    const convertContentToHTMLPersonActivities = () => {
        let currentContentAsHTML = draftToHtml(convertToRaw(personActivitiesState.getCurrentContent()));
        setConvertedContentPersonActivities(currentContentAsHTML);
        setOtherActivities(currentContentAsHTML);
    }

    const uploadProfilePicture = async (e) => {
        e.preventDefault();

        let data = new FormData();
        data.append('image_for', 'profile');
        // data.append('array_index', null)
        data.append('picture_path', e.target.files[0]);
        setProfilePhoto(e.target.files[0])
        setProfilePictureFile(URL.createObjectURL(e.target.files[0]))

        data.forEach(d => console.log('FILE UPLOAD DATA ::::::: ', d))
    };

    const handleEducation = async (idx, event) => {
        console.log('handleEducation OBJ === ', idx, event.target.name, event.target.value);
        setEducation(prev => {
            const newState = prev.map((obj, i) => {
                if (i === idx)
                    return { ...obj, [event.target.name]: event.target.value }
                return obj;
            })
            return newState
        });
    };

    const handleCareer = async (idx, event) => {
        console.log('handleEducation OBJ === ', idx, event.target.name, event.target.value);
        setCareer(prev => {
            const newState = prev.map((obj, i) => {
                if (i === idx)
                    return { ...obj, [event.target.name]: event.target.value }
                return obj;
            })
            return newState
        });
    };

    const handleCompensation = async (idx, event) => {
        console.log('handleEducation OBJ === ', idx, event.target.name, event.target.value);
        setCompensation(prev => {
            const newState = prev.map((obj, i) => {
                if (i === idx)
                    return { ...obj, [event.target.name]: event.target.value }
                return obj;
            })
            return newState
        });
    };


    const handleReferences = async (idx, event) => {
        console.log('handleEducation OBJ === ', idx, event.target.name, event.target.value);
        setReferences(prev => {
            const newState = prev.map((obj, i) => {
                if (i === idx)
                    return { ...obj, [event.target.name]: event.target.value }
                return obj;
            })
            return newState
        });
    };

    const handleKnownFor = (knownFor) => {
        setKnownForList(prev => [...prev, knownFor])
        // setAliaz("")
    }

    const handleParentCompanyList = (data) => {
        console.log('Selected Company From DropDown ::: ', data)
        setParentCompanyList(prev => [...prev, data])
        setTimeout(() => {
            // setCompanySuggestions([]);
            SetSuggDropDownState(false);
        }, 100);
    }

    const handleAka = (aliaz) => {
        setAkaList(prev => [...prev, aliaz])
        // setAliaz("")
    }

    const handleRelevancy = (event) => {
        const { target: { value }, } = event;
        setRelevancyList(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleParentCompany = (company) => {
        setParentCompanyList(prev => [...prev, company])
    }

    const handleDeleteKnownFor = (e) => {
        setKnownForList(knownForList.filter((a) => a !== e))
        // console.log(e)
    }

    const handleDeleteAliaz = (e) => {
        setAkaList(akaList.filter((a) => a !== e))
        // console.log(e)
    }

    const handleDeleteRelevancy = (e) => {
        setRelevancyList(relevancyList.filter((a) => a !== e))
        // console.log(e)
    }

    const handleDeleteParentCompany = (e) => {
        setParentCompanyList(parentCompanyList.filter((a) => a.cik !== e.cik))
    }

    const handleSave = async (isHyperlinked) => {
        setLoading(true)
        let data = new FormData();
        parentCompanyList.length > 0 ? parentCompanyList.forEach(a => data.append("parent_companies", JSON.stringify(a))) : data.append("parent_companies", parentCompanyList);
        akaList.length > 0 ? akaList.forEach(a => data.append("aliaz", a)) : data.append("aliaz", akaList);
        relevancyList.length > 0 ? relevancyList.forEach(a => data.append("relevancy", a)) : data.append("relevancy", relevancyList);
        knownForList.length > 0 ? knownForList.forEach(a => data.append("known_for", a)) : data.append("known_for", knownForList);
        education.length > 0 ? education.forEach(a => data.append("education", JSON.stringify(a))) : data.append("education", education);
        career.length > 0 ? career.forEach(a => data.append("career", JSON.stringify(a))) : data.append("career", career);
        compensation.length > 0 ? compensation.forEach(a => data.append("compensation", JSON.stringify(a))) : data.append("compensation", compensation);
        references.length > 0 ? references.forEach(a => data.append("references", JSON.stringify(a))) : data.append("references", references);
        data.append("gen_hyperlink", isHyperlinked)
        data.append("insider_name", personName)
        data.append("insider_cik", personCIK)
        data.append("overview", convertedContentPersonOverview)
        data.append("earlyLife", convertedContentPersonEarlyLife)
        data.append("otherActivities", convertedContentPersonActivities)
        data.append("recognition", convertedContentPersonRecognition)
        data.append("Profilepicture", profilePicture);
        data.append("image_for", 'profile');
        data.append('picture_path', profilePhoto);

        data.forEach(d => console.log('FINAL DATA READY TO INSERT ::::::: ', d))

        await axios({
            method: 'POST',
            url: '/api/person',
            Headers: { 'Content-Type': 'multipart/form-data; boundary="boundary"; application/json' },
            data: data
        }).then(resp => {
            console.log('UPLOAD IMAGE API RESP === ', resp.data.message);
            // setProfilePicture(resp.data.message);
            // data.delete('insider_cik');
            // data.delete('image_for');
            setLoading(false)
            if (resp.data.success) {
                alert('Profile Saved')
                history.push('/people/edit/' + resp.data.message[0].sk_txn_sec_people_profile)
            } else {
                alert("Error While Saving Profile")
            }
        }).catch(error => {
            alert("Error ::", error);
            console.error('UPLOAD IMAGE API ERROR === ', error);
            setLoading(false)
        });
    };

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 400);
        };
    };

    const handleCompanyData = (inputData) => {
        if (inputData || inputData !== ' ') {
            setSuggLoading(true);
            setCompanySuggestions([]);
            axios.get('/api/search/companies/?s=' + inputData)
                .then((resp) => {
                    SetSuggDropDownState(true);
                    setCompanySuggestions(resp.data.message);
                    setSuggLoading(false);
                });
        } else {
            setCompanySuggestions([]);
            SetSuggDropDownState(false);
        }
    };

    const optimizedFn = useCallback(debounce(handleCompanyData), []);

    return (
        <>
            <Header />
            {loading ? <Box height='80vh' display='flex' justifyContent='center' alignItems='center'><CircularProgress /></Box> : <>
                <Box mx={5} boxShadow={2}>
                    <Box display="flex" flexDirection="row" justifyContent={"space-between"} alignContent="center" alignItems="center" p={1}>
                        <Button variant='contained' color='inherit' size='small' onClick={() => history.push("/home")}>&#10094;{' back'}</Button>
                        <h2 style={{ margin: 0, padding: 0, fontWeight: 400, color: colors.blueGrey[700] }}>Add New Person To Database</h2>
                        <Box>
                            <Button variant='contained' color='secondary' size='small' style={{ marginRight: "10px" }} onClick={() => handleSave(true)}>Hyperlink & Save</Button>
                            <Button variant='contained' color='primary' size='small' onClick={() => handleSave(false)}>Save Without Hyperlinking</Button>
                        </Box>
                    </Box>
                    <hr />
                    <Box display="flex" width="100%" justifyContent="space-around">
                        <Box width="32%" m={1} display="flex" flexDirection="column">
                            <Box my={0}>
                                <TextField
                                    id="standard-basic"
                                    label="Enter Name"
                                    variant="standard"
                                    fullWidth
                                    // style={{ width: "350px" }}
                                    value={personName}
                                    onChange={(e) => setPersonName(e.target.value.trim())}
                                />
                            </Box>
                            <Box mt={5}>
                                <TextField
                                    variant='standard'
                                    name='Known For:'
                                    label="Person CIK"
                                    placeholder='Optional: SEC CIK'
                                    fullWidth
                                    // style={{ width: '250px' }}
                                    value={personCIK}
                                    onChange={(e) => setPersonCIK(e.target.value)}
                                />
                            </Box>
                            <Box mt={5}>
                                {/* <TextField variant='standard' name='Known For:' sx={{ marginLeft: 1 }}
                            label="Known For:"
                            placeholder='Known For:'
                            style={{ width: '250px' }}
                            value={knownFor}
                            onChange={(e) => setKnownFor(e.target.value)}
                        /> */}
                                <TextField
                                    id="knownFor"
                                    name="known-for"
                                    variant='standard'
                                    placeholder='Known For...'
                                    defaultValue={knownFor}
                                    label="Known For"
                                    fullWidth
                                    // style={{ width: '250px' }}
                                    value={knownFor}
                                    onChange={(e) => setKnownFor(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                <IconButton sx={{ visibility: knownFor ? "visible" : "hidden" }} onClick={() => setKnownFor("")} size="small">
                                                    <ClearIcon />
                                                </IconButton>
                                                <IconButton sx={{ visibility: knownFor ? "visible" : "hidden" }} onClick={() => handleKnownFor(knownFor)} size="small">
                                                    <AddBoxIcon />
                                                </IconButton>
                                            </>
                                        ),
                                    }}
                                    sx={{ "& .Mui-focused .MuiIconButton-root": { color: "primary.main" } }}
                                />
                                <Box display="flex" flexWrap="wrap">
                                    {knownForList?.map((a) => (
                                        <Chip label={a} style={{ margin: '3px' }} onDelete={(e) => handleDeleteKnownFor(a)} />
                                    )
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Box width="32%" m={1} display="flex" flexDirection="column">
                            <Box display="flex" flexDirection="column" position="relative">
                                <Box>
                                    <TextField
                                        type="text"
                                        variant='standard'
                                        className="search"
                                        placeholder="Enter Parent Companies Name..."
                                        label='Parent Companies'
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => {
                                            if (e.target.value.trim()) {
                                                setSuggLoading(true);
                                                setParentCompany(e.target.value)
                                                optimizedFn(e.target.value)
                                            } else
                                                setCompanySuggestions([])
                                        }}
                                        onFocus={() => SetSuggDropDownState(true)}
                                        onBlur={() => setTimeout(() => {
                                            SetSuggDropDownState(false)
                                        }, 100)}
                                        defaultValue={parentCompany}
                                        value={parentCompany}
                                        InputProps={{
                                            endAdornment: (
                                                <>
                                                    {suggLoading ? <HourglassTopRounded size="small" color="info" /> : null}
                                                    <IconButton onClick={() => { setParentCompany(""); setCompanySuggestions([]) }} size="small">
                                                        <ClearIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => handleParentCompanyList({ cik: "", company_name: parentCompany })} size="small">
                                                        <AddBoxIcon />
                                                    </IconButton>
                                                </>
                                            )
                                        }}
                                    />
                                    {companySuggestion.length && suggDropDownState
                                        ? <Box style={{ maxHeight: '180px', backgroundColor: '#f3f6f9d9', position: 'absolute', width: '100%', zIndex: 10, overflow: "hidden", overflowY: "scroll", boxShadow: '#CCC 10px 10px 10px', paddingBottom: '5px' }}>
                                            {companySuggestion.map((option, idx) =>
                                                <MenuItem
                                                    sx={{ fontSize: 13 }}
                                                    key={idx}
                                                    value={option.company_name}
                                                    onClick={(e) => {
                                                        handleParentCompanyList(option);
                                                    }}
                                                >
                                                    {option.company_name}
                                                </MenuItem>
                                            )}
                                        </Box> : null}
                                </Box>
                            </Box>
                            <Box>
                                {parentCompanyList?.map((c) =>
                                    <Chip label={c.company_name} style={{ margin: '3px' }} onDelete={() => handleDeleteParentCompany(c)} />
                                )}
                            </Box>
                            <Box mt={5}>
                                <TextField
                                    id="aka"
                                    name="aka"
                                    label="aka (Also Known As)"
                                    variant='standard'
                                    placeholder='aka'
                                    defaultValue={aka}
                                    value={aka}
                                    onChange={(event) => setAka(event.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                <IconButton sx={{ visibility: aka ? "visible" : "hidden" }} onClick={() => setAka("")} size="small">
                                                    <ClearIcon />
                                                </IconButton>
                                                <IconButton sx={{ visibility: aka ? "visible" : "hidden" }} onClick={() => handleAka(aka)} size="small">
                                                    <AddBoxIcon />
                                                </IconButton>
                                            </>
                                        ),
                                    }}
                                    sx={{ "& .Mui-focused .MuiIconButton-root": { color: "primary.main" } }}
                                />
                            </Box>
                            <Box>
                                {akaList?.map((a) => (
                                    <Chip label={a} style={{ margin: '3px' }} onDelete={(e) => handleDeleteAliaz(a)} />
                                )
                                )}
                            </Box>
                            <Box mt={5}>
                                <FormControl sx={{ m: 1, width: 400 }}>
                                    <InputLabel id="person-category-multiple-checkbox-label">Person Relevant To</InputLabel>
                                    <Select
                                        labelId="person-category-multiple-checkbox-label"
                                        id="person-category-multiple-checkbox"
                                        multiple
                                        value={relevancyList}
                                        onChange={handleRelevancy}
                                        input={<OutlinedInput label="Person Relevant To" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {relevancy.map((rel) => (
                                            <MenuItem key={rel} value={rel}>
                                                <Checkbox checked={relevancyList.indexOf(rel) > -1} />
                                                <ListItemText primary={rel} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                {relevancyList?.map((r) => (
                                    <Chip label={r} style={{ margin: '3px' }} onDelete={(e) => handleDeleteRelevancy(r)} />
                                )
                                )}
                            </Box>
                        </Box>
                        <Box width="32%" m={1} display="flex" flexDirection="column">
                            <Box position="relative">
                                <IconButton sx={{ position: "absolute", top: -10, right: 90, visibility: profilePictureFile ? "visible" : "hidden" }} onClick={() => { setProfilePictureFile(null); setProfilePhoto(null) }} size="small">
                                    <ClearIcon color="error" size="small" />
                                </IconButton>
                                <img width="175px" src={profilePictureFile} />
                            </Box>
                            <Box>
                                <Button variant="text" component="label" size='small' endIcon={<PhotoCamera fontSize='small' name='upload Pic' />}>
                                    Upload Profile Pic
                                    <input hidden accept="image/*" type="file" name="insider_profile_picture_path"
                                        onChange={(e) => uploadProfilePicture(e)}
                                    />
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <hr />
                    <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                        <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Overview</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" p={1} m={1}>
                        <Box width="100%">
                            <Editor
                                editorState={personOverviewState}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                onEditorStateChange={handleEditorChangePersonOverview}
                                toolbar={{
                                    link: { inDropdown: true },
                                    history: { inDropdown: true },
                                }}
                                placeholder={('Write here ...')}
                                editorStyle={{ height: "350px" }}
                            />
                        </Box>
                    </Box>
                    <hr />
                    <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                        <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Early Life</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" p={1} marginTop={1} height="500px">
                        {/* <TextField id="early-life" name="insider_early_life" label="Person Early Live" InputLabelProps={{ shrink: true }} fullWidth multiline rows={10} value={earlyLife} onChange={e => handleEarlyLife(e.target.name, e.target.value)} /> */}
                        <Editor
                            editorState={personEarlyLifeState}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={handleEditorChangePersonEarlyLife}
                            toolbar={{
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                                image: {
                                    defaultSize: {
                                        height: 'auto',
                                        width: '300px',
                                    },
                                }
                            }}
                            placeholder={('Write here ...')}
                            editorStyle={{ maxHeight: "350px" }}
                        />
                    </Box>
                    <hr />
                    <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                        <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Education</Typography>
                    </Box>
                    {education.length &&
                        <Box display="flex" flexDirection="column" justifyContent="space-evenly" p={1} marginTop={1}>
                            {education.map((edu, idx) =>
                                <Box display="flex" justifyContent="space-evenly" alignContent="center" alignItems="center" mb={1} pb={1}>
                                    {idx + 1}<TextField id="education-year-1" variant="standard" name="year" label="Year" InputLabelProps={{ shrink: true }} value={edu.year} onChange={e => handleEducation(idx, e)} />
                                    <TextField id="education-degree-1" variant="standard" name="degree" label="Degree" InputLabelProps={{ shrink: true }} value={edu.degree} onChange={e => handleEducation(idx, e)} />
                                    <TextField id="education-school-1" variant="standard" name="school" label="School" InputLabelProps={{ shrink: true }} value={edu.school} onChange={e => handleEducation(idx, e)} />
                                </Box>)}
                        </Box>}
                    <Box>
                        <Button onClick={() => setEducation(prev => [...prev, { year: '', degree: '', school: '' }])}> <AddBoxIcon />Add Education</Button>
                    </Box>
                    <hr />
                    <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                        <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Career</Typography>
                    </Box>
                    {career.length &&
                        <Box display="flex" flexDirection="column" justifyContent="space-evenly" p={1} marginTop={1}>
                            {career.map((car, idx) =>
                                <Box display="flex" justifyContent="space-evenly" alignContent="center" alignItems="center" mr={30} mb={1} pb={1}>
                                    <Typography variantt="body1">{idx + 1}:</Typography>
                                    <TextField id="company-1" variant="standard" name="company" label="Company" InputLabelProps={{ shrink: true }} value={car.company} onChange={e => handleCareer(idx, e)} />
                                    <TextField id="current-title-1" variant="standard" name="current_title" label="Title" InputLabelProps={{ shrink: true }} value={car.current_title} onChange={e => handleCareer(idx, e)} />
                                </Box>)}
                        </Box>
                    }
                    <Box>
                        <Button onClick={() => setCareer(prev => [...prev, { company: "", current_title: "" },])}> <AddBoxIcon />Add Career</Button>
                    </Box>
                    <hr />
                    <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                        <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Compensation</Typography>
                    </Box>
                    {compensation.length &&
                        <Box display="flex" flexDirection="column" justifyContent="space-evenly" p={1} marginTop={1}>
                            {compensation.map((comp, idx) =>
                                <Box display="flex" justifyContent="space-evenly" alignContent="center" alignItems="center" mb={1} pb={1}>
                                    <Typography variantt="body1">{idx + 1}:</Typography>
                                    <TextField id="company-1" variant="standard" name="company" label="Company Name" InputLabelProps={{ shrink: true }} value={comp.company} onChange={e => handleCompensation(idx, e)} />
                                    <TextField id="compensation-1" variant="standard" name="compensation" label="Compensation" InputLabelProps={{ shrink: true }} value={comp.compensation} onChange={e => handleCompensation(idx, e)} />
                                    <TextField id="other-compensation-1" variant="standard" name="other_compensation" label="Other Compensation" InputLabelProps={{ shrink: true }} value={comp.other_compensation} onChange={e => handleCompensation(idx, e)} />
                                </Box>)}
                        </Box>
                    }
                    <Box>
                        <Button onClick={() => setCompensation(prev => [...prev, { company: "", compensation: null, other_compensation: null }])}> <AddBoxIcon />Add Compensation</Button>
                    </Box>
                    <hr />
                    <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                        <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Other Activities</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" p={1} marginTop={1} height='500px'>
                        <Editor
                            editorState={personActivitiesState}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={handleEditorChangePersonActivities}
                            toolbar={{
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                            }}
                            placeholder={('Write here ...')}
                            editorStyle={{ maxHeight: "350px" }}
                        />
                    </Box>
                    <hr />
                    <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                        <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>Recognition</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" p={1} marginTop={1} height='500px'>
                        {/* <TextField id="recognition" name="insider_recognitions" label="Recognition" InputLabelProps={{ shrink: true }} fullWidth multiline rows={10} value={recognition} onChange={e => handleRecognition(e.target.name, e.target.value)} /> */}
                        <Editor
                            editorState={personRecognitionState}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={handleEditorChangePersonRecognition}
                            toolbar={{
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                            }}
                            placeholder={('Write here ...')}
                            editorStyle={{ maxHeight: "350px" }}
                        />
                    </Box>
                    <hr />
                    <Box width="100%" flexDirection={"column"} justifyContent="flex-start" ml={1} mt={4}>
                        <Typography variant='h3' fontSize={18} fontWeight={700} color={colors.blueGrey[600]} sx={{ textDecorationLine: 'underline', textAlign: 'left' }} mr={1} mb={0} pb={0}>References</Typography>
                    </Box>
                    {references.length &&
                        <Box display="flex" flexDirection="column" justifyContent="flex-start" p={1} marginTop={1} marginBottom={5}>
                            {references.map((ref, idx) =>
                                <Box display="flex" justifyContent="flex-start" alignContent="center" alignItems="center">
                                    <Box width="5%" m={1}><Typography variantt="body1">{idx + 1}:</Typography></Box>
                                    <Box width="50%" m={1}><TextField id="title-1" variant="standard" name="title" label="Title" InputLabelProps={{ shrink: true }} fullWidth value={ref.title} onChange={e => handleReferences(idx, e)} /></Box>
                                    <Box width="45%" m={1}><TextField id="url-1" variant="standard" name="url" label="URL" InputLabelProps={{ shrink: true }} fullWidth value={ref.url} onChange={e => handleReferences(idx, e)} /></Box>
                                </Box>
                            )}
                        </Box>
                    }
                    <Box>
                        <Button onClick={() => setReferences(prev => [...prev, { title: "", url: "" },])}> <AddBoxIcon />Add References</Button>
                    </Box>
                    <hr />
                    <Box display="flex" flexDirection="row" justifyContent={"space-between"} alignContent="center" alignItems="center" p={1}>
                        <Button variant='outlined' color='warning' size='small' onClick={() => history.push("/home")}>{'<< back'}</Button>
                        <Box>
                            <Button variant='contained' color='secondary' size='small' style={{ marginRight: "10px" }} onClick={() => handleSave(true)}>Hyperlink & Save</Button>
                            <Button variant='contained' color='primary' size='small' onClick={() => handleSave(false)}>Save Without Hyperlinking</Button>
                        </Box>
                    </Box>
                </Box>
            </>}
        </>
    )
}

export default AddPerson