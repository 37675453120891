import { useState, createContext, useContext, useEffect } from "react";
import AuthService from "../services/auth.service";

const StoreContext = createContext(null)

export const DataProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState();
    const [personName, setPersonName] = useState(null);
    const [personCik, setPersonCik] = useState(null);
    const [peopleList, setPeopleList] = useState([]);
    const [personProfile, setPersonProfile] = useState();

    const [masterCategories, setMasterCategories] = useState([]);
    const [masterSubCategories, setMasterSubCategories] = useState([]);
    const [masterCategoriesSubCategoriesTree, setMasterCategoriesSubCategoriesTree] = useState([]);
    const [assignedCategoriesAndSubCategories, setAssignedCategoriesAndSubCategories] = useState();
    const [headlineText, setHeadlineText] = useState("");
    const [keywordText, setKeywordText] = useState("");
    const [companyTicker, setCompanyTicker] = useState("");
    const [macroIndicatorName, setMacroIndicatorName] = useState("");
    const [searchOn, setSearchOn] = useState(false);
    const [articlesList, setArticlesList] = useState([]);
    const [articleContent, setArticleContent] = useState(null);
    const [articleIndex, setArticleIndex] = useState(0);
    const [articlesSize, setArticlesSize] = useState(0);

    const [screenLoading, setScreenLoading] = useState(false);

    const saveArticlesList = (articlesList) => setArticlesList(articlesList);

    const resetSearchBar = () => {
        setHeadlineText("");
        setKeywordText("");
        setCompanyTicker("");
        setMacroIndicatorName("");
        setPersonName("")
        setPersonCik("")
        setPeopleList([])
        // setSearchOn(true);
    };

    useEffect(async () => { setCurrentUser(await AuthService.getCurrentUser()) }, []);
    console.log('USE-STORE ARTICLE-INDEX =============== ', articleIndex);

    return (
        <StoreContext.Provider value={{
            currentUser, setCurrentUser,
            searchOn, setSearchOn,
            personName, setPersonName,
            personCik, setPersonCik,
            peopleList, setPeopleList,
            personProfile, setPersonProfile,
            resetSearchBar,
            screenLoading, setScreenLoading
        }}>
            {children}
        </StoreContext.Provider>
    )
}

export const useStore = () => {
    return useContext(StoreContext)
}
