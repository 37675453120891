import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import { Redirect } from 'react-router';

import { DataProvider } from "./store/StoredData"

import Home from './views/Home';
import LoginComponent from "./components/login.component";
import ViewPerson from './views/ViewPerson';
import EditPerson from './views/EditPerson';
import AddPerson from './views/AddPerson';

import AuthService from "./services/auth.service";

import './App.css';

function App(props) {

  const history = useHistory();
  const [currentUser, setCurrentUser] = useState(localStorage.getItem('user'));
  const [session, setSession] = useState(true);

  useEffect(async () => {
    setCurrentUser(await AuthService.getCurrentUser());
  }, []);

  return (
    <div className="App">
      {console.log('APP JS Current User - ', currentUser)}
      <DataProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path={["", "/"]} render={() => (
              !currentUser ? <Redirect to={"/login"} /> : <Redirect {...props} session={session} setSession={setSession} currentUser={currentUser} to={"/home"} />
            )} />
            <Route exact path={"/home"} render={() => (
              currentUser ? <Home currentUser={currentUser} /> : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/people/add"} render={() => (
              currentUser ? <AddPerson currentUser={currentUser} /> : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/people/view/:id"} render={() => (
              currentUser ? <ViewPerson currentUser={currentUser} /> : <Redirect to={"/login"} />
            )} />
            <Route exact path={"/people/edit/:id"} render={() => (
              currentUser ? <EditPerson currentUser={currentUser} /> : <Redirect to={"/login"} />
            )} />

            <Route exact path={"/login"} render={() => (
              !currentUser ? <LoginComponent {...props} setCurrentUser={setCurrentUser} session={session} setSession={setSession} /> : <Redirect to={"/"} />
            )}
            />
          </Switch>
        </BrowserRouter>
      </DataProvider>
    </div>
  );
}

export default App;
