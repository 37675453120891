import React from 'react'
import { Helmet } from 'react-helmet'

const MetaTitle = (props) => {
  return (
    <>
    <Helmet>
        {props.title && <title>{props.title} | People | Insiders</title>}
        {props.desc && <meta name="description" content={props.desc}></meta>}
        {props.keywords && <meta name="keywords" content={props.keywords}></meta>}
    </Helmet>
    </>
  )
}

export default MetaTitle